<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">
              {{ labels.bsnDate }}
            </div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    v-model="inputDateRangeValue"
                    type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.gcDiv }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="gcDiv"
                    v-model="searchConditions.gcDiv"
                    :dataSource="searchOptions.gcDivOptions"
                    :allowFiltering="false"
                    :fields="commonCodeFields"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.storeCode }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.storeCode"
                    :dataSource="searchOptions.storeCodeOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="180"
                    cssClass="lookup-condition-multiselect"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.classCode }}
            </div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect
                    v-model="searchConditions.classCode"
                    :dataSource="searchOptions.classCodeOptions"
                    :fields="commonCodeFields"
                    placeHolder="전체"
                    width="180"
                    cssClass="lookup-condition-multiselect"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.productName }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.productName"
                  @change="onViewStoreSales"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewStoreSales"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">판매 내역 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="excel"
                  >
                    Excel
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="salesDetailsByItemGrid"
                  :allowExcelExport="true"
                  :allowGrouping="true"
                  :allowPaging="true"
                  :allowResizing="true"
                  :provides="grid"
                  :columns="salesDetailsByItemGridColumn"
                  :dataSource="salesDetailsByItemInfoList"
                  :groupSettings="groupSettings"
                  :pageSettings="salesDetailsByItemPageSettings"
                  :aggregates="salesDetailsByItemGridAggregates"
                  @actionComplete="salesDetailsByItemGridActionComplete"
                  @queryCellInfo="salesDetailsByItemGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.productName .content .item.input {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import {numberWithCommas} from '@/utils/number';
import {commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Page,
  Resize,
} from '@syncfusion/ej2-vue-grids';
import ejsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import InputDateRange from '@/components/common/datetime/InputDateRange';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import GolfERPService from '@/service/GolfERPService';
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import InputText from "@/components/common/text/InputText";
import ReportView from "@/components/common/report/ReportView";
import { getPaymentLineInfo } from "@/api/systemConfig";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: 'SalesDetailsByItem',
  components: {
    InputText,
    ejsGridWrapper,
    InputDateRange,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      count: 0,
      now: null,
      labels: {
        bsnDate: '영업일자',
        gcDiv: "구분",
        storeCode: '매장',
        classCode: '품목분류',
        productName: "품목명",
      },
      salesDetailsByItemPageSettings: {pageSize: 50},
      salesDetailsByItemGridColumn: [
        {
          headerText: '대분류',
          field: 'className1',
          width: 80,
          type: 'string',
        },
        {
          headerText: '중분류',
          field: 'className2',
          width: 80,
          type: 'string',
        },
        {
          headerText: '소분류',
          field: 'className3',
          width: 90,
          type: 'string',
        },
        {
          headerText: '품목명',
          field: 'productName',
          width: 120,
          type: 'string',
        },
        {
          headerText: '정상',
          columns: [
            {
              headerText: '수량',
              field: 'norSaleQty',
              type: 'number',
              width: 70,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'norTotAmt',
              type: 'number',
              width: 110,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '캐디',
          columns: [
            {
              headerText: '수량',
              field: 'caddieSaleQty',
              type: 'number',
              width: 60,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'caddieTotAmt',
              type: 'number',
              width: 90,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '임직원',
          columns: [
            {
              headerText: '수량',
              field: 'stffSaleQty',
              type: 'number',
              width: 60,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'stffTotAmt',
              type: 'number',
              width: 90,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '행사',
          columns: [
            {
              headerText: '수량',
              field: 'eventSaleQty',
              type: 'number',
              width: 60,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'eventTotAmt',
              type: 'number',
              width: 90,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '할인',
          columns: [
            {
              headerText: '수량',
              field: 'dcSaleQty',
              type: 'number',
              width: 60,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'dcTotAmt',
              type: 'number',
              width: 90,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '자가',
          columns: [
            {
              headerText: '수량',
              field: 'selfSaleQty',
              type: 'number',
              width: 60,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: '금액',
              field: 'selfTotAmt',
              type: 'number',
              width: 90,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
        {
          headerText: '합계',
          columns: [
            {
              headerText: '수량',
              field: 'totSaleQty',
              type: 'number',
              width: 70,
              textAlign: 'right',
              isNumericType: true,
            },
            {
              headerText: 'NET',
              field: 'totNetAmt',
              type: 'number',
              width: 110,
              textAlign: 'right',
              isNumericType: true,
            },{
              headerText: 'VAT',
              field: 'totVatAmt',
              type: 'number',
              width: 100,
              textAlign: 'right',
              isNumericType: true,
            },{
              headerText: '금액',
              field: 'totTotAmt',
              type: 'number',
              width: 120,
              textAlign: 'right',
              isNumericType: true,
            },
          ],
        },
      ],
      grid: [
        Aggregate,
        Filter,
        Resize,
        Group,
        ExcelExport,
        ForeignKey,
        Page,
      ],
      salesDetailsByItemGridAggregates: [
        {
          columns: [
            {
              field: 'productName',
              aggregationType: 'TotalCaption',
              customAggregate: '합계',
            },
            {
              field: 'norSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'norTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'caddieSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'caddieTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'stffSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'stffTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'eventSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'eventTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'dcSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'dcTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'selfSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'selfTotAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'totSaleQty',
              aggregationType: 'TotalSum',
            },
            {
              field: 'totNetAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'totVatAmt',
              aggregationType: 'TotalSum',
            },
            {
              field: 'totTotAmt',
              aggregationType: 'TotalSum',
            },
          ],
        },
      ],
      commonCodeFields: {text: 'comName', value: 'comCode'},
      salesDetailsByItemInfoList: [],
      searchOptions: {
        storeCodeOptions: commonCodesGetCommonCode('STORE_CODE', true),
        classCodeOptions: commonCodesGetCommonCode('CLASS_CODE_1', true),
        gcDivOptions: commonCodesGetCommonCode("GC_DIV", true),
      },
      searchConditions: {
        bsnStartDate: moment()
            .set('date', 1)
            .format(DATE_FORMAT_YYYY_MM_DD),
        bsnEndDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        storeCode: [],
        classCode: [],
        productName: null,
        gcDiv: "%",
      },
      groupSettings: {
        columns: [],
        showDropArea: false,
      },
    };
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  async created() {
    const nowMoment = await GolfERPService.fetchNow();
    this.now = nowMoment.toDate();
    const from = moment(this.now)
        .set('date', 1)
        .format(DATE_FORMAT_YYYY_MM_DD);
    const to = nowMoment.format(DATE_FORMAT_YYYY_MM_DD);
    this.inputDateRangeValue = {from, to};

    this.searchOptions.gcDivOptions.unshift({ comCode: "%", comName: "전체" });
  },
  mounted() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false; // TODO : 팝업 있을 경우, 삽입
    },
    datetimeString() {
      return moment(this.now).format(`YYYY-MM-DD HH:mm:ss`);
    },
    inputDateRangeValue: {
      get() {
        return {
          from: this.searchConditions.bsnStartDate,
          to: this.searchConditions.bsnEndDate,
        };
      },
      set(inputDateRangeValue) {
        this.searchConditions.bsnStartDate = inputDateRangeValue.from;
        this.searchConditions.bsnEndDate = inputDateRangeValue.to;
      },
    },
  },
  methods: {
    numberWithCommas,
    async onViewStoreSales() {
      if (!this.isValidBsnDate()) {
        return;
      }
      await this.fetchSales();
    },
    isValidBsnDate() {
      if (!this.searchConditions.bsnStartDate) {
        this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['영업일자']),
        );
        return false;
      }
      if (!this.searchConditions.bsnEndDate) {
        this.errorToast(
            this.$t('main.validationMessage.requiredMessage', ['종료일자']),
        );
        return false;
      }

      return true;
    },
    salesDetailsByItemGridActionComplete() {
      this.count = numberWithCommas(
          this.$refs.salesDetailsByItemGrid?.getGridBatchCount() || 0,
      );
    },
    excel() {
      this.$refs.salesDetailsByItemGrid.excelExport();
    },

    // API
    async fetchSales() {
      this.salesDetailsByItemInfoList = (await GolfErpAPI.fetchSalesDetailsByItem({
        salesDateFrom: this.inputDateRangeValue.from,
        salesDateTo: this.inputDateRangeValue.to,
        storeCodes: this.searchConditions.storeCode,
        classCodes: this.searchConditions.classCode,
        productName: this.searchConditions.productName,
        gcDiv: this.searchConditions.gcDiv === "%" ? null : this.searchConditions.gcDiv,
      })).map(item => {
        item.norSaleQty = item.norSaleQty || 0;
        item.norTotAmt = item.norTotAmt || 0;
        item.caddieSaleQty = item.caddieSaleQty || 0;
        item.caddieTotAmt = item.caddieTotAmt || 0;
        item.stffSaleQty = item.stffSaleQty || 0;
        item.stffTotAmt = item.stffTotAmt || 0;
        item.eventSaleQty = item.eventSaleQty || 0;
        item.eventTotAmt = item.eventTotAmt || 0;
        item.dcSaleQty = item.dcSaleQty || 0;
        item.dcTotAmt = item.dcTotAmt || 0;
        item.selfSaleQty = item.selfSaleQty || 0;
        item.selfTotAmt = item.selfTotAmt || 0;
        item.totSaleQty = item.totSaleQty || 0;
        item.totTotAmt = item.totTotAmt || 0;
        return item;
      }) || [];
    },
    salesDetailsByItemGridQueryCellInfo(args) {
      const {
        column: {
          field
        },
        data
      } = args;

      if (
        field === "norSaleQty" ||
        field === "norTotAmt" ||
        field === "caddieSaleQty" ||
        field === "caddieTotAmt" ||
        field === "stffSaleQty" ||
        field === "stffTotAmt" ||
        field === "eventSaleQty" ||
        field === "eventTotAmt" ||
        field === "dcSaleQty" ||
        field === "dcTotAmt" ||
        field === "selfSaleQty" ||
        field === "selfTotAmt" ||
        field === "totSaleQty" ||
        field === "totTotAmt"
      ) {
        if (data[field] === 0) {
          args.cell.innerText = "-";
        }
      }
    },
    async print() {
      const result = this.$refs.salesDetailsByItemGrid.getGridBatchData();
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }
      const [{ tsConfSanctnDetail }] = (await getPaymentLineInfo(true)).value.sanctnList.filter((data)=> data.sanctnLineId === 44  );

      const searchOptionsList = [
        {
          key: this.labels.bsnDate,
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: this.labels.storeCode,
          value:
            this.searchConditions.storeCode.length < commonCodesGetCommonCode("STORE_CODE").length
              ? this.searchConditions.storeCode
                .map((item) => commonCodesGetComName("STORE_CODE", item))
                .join(", ")
              : null,
        },
        {
          key: this.labels.classCode,
          value:
            this.searchConditions.classCode.length < commonCodesGetCommonCode("CLASS_CODE_1").length
              ? this.searchConditions.classCode
                .map((item) => commonCodesGetComName("CLASS_CODE_1", item))
                .join(", ")
              : null,
        },
        {
          key: this.labels.productName,
          value: this.searchConditions.productName,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          selectSanctnLineList: tsConfSanctnDetail,
          searchOptions,
          // subTotSumFlag: this.subTotSumFlag ? "true" : "false",
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    }
  },
};
</script>
